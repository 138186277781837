import React from 'react'
import './portfolio.css'
import { Link } from 'react-router-dom';

const Portfolio = () => {
    const projects = [
        {
            title: "Integrating Self-Attention layer with ResNet152V2",
            description: "Image Classification using ResNet152V2 architecture enhanced with Self-Attention Layer, designed to improve classification accuracy.",
            link: "/project/resnet152v2-attention",
        },
        {
            title: "Sentiment Analysis with Custom E-commerce Dataset",
            description: "This project analyzes customer sentiment from an e-commerce platform using a self-made dataset. It compares the performance of three models: RoBERTa, LSTM, and GRU, highlighting key differences",
            link: "#",
        },
        {
            title: "Customer Segmentation",
            description: "K-Means clustering project to segment customers based on purchasing behavior, identifying key customer groups.",
            link: "#",
        },
        {
            title: "Churn Prediction Model",
            description: "A predictive model to identify customers at risk of leaving, using machine learning techniques.",
            link: "#",
        },
    ];

    return (
        <section className="portfolio section" id="portfolio">
            <h2 className="section__title">Portfolio</h2>
            <span className="section__subtitle">My Recent Work</span>

            <div className="portfolio__container container grid">
                {projects.map((project, index) => (
                    <div key={index} className="portfolio__content">
                        <h3 className="portfolio__title">{project.title}</h3>
                        <p className="portfolio__description">{project.description}</p>
                        <Link to={project.link} className="portfolio__button">
                            View Project <i className="uil uil-arrow-right portfolio__button-icon"></i>
                        </Link>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Portfolio