import React from 'react';
import "./App.css";
import Header from './components/header/Header';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/home/Home';
import About from './components/about/About';
import Skills from './components/skills/Skills';
import Qualification from './components/qualification/Qualification';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import ScrollUp from './components/scrollup/ScrollUp';
import Portfolio from './components/portfolio/Portfolio';
import { Analytics } from "@vercel/analytics/react";

import ImageClassificationResNet152V2 from './pages/projects/ImageClassificationResNet152V2';

function App() {
  return (
    <Router>
    <Header />
    <main className='main'>
        <Routes>
          <Route 
            path="/" 
            element={
              <>
                <Home />
                <About />
                <Skills />
                <Portfolio />
                <Qualification />
                <Contact />
              </>
            } 
          />

          <Route path='/project/resnet152v2-attention' element={<ImageClassificationResNet152V2></ImageClassificationResNet152V2>}/>

        </Routes>
      </main>
    <Footer />
    <ScrollUp />
    <Analytics/>
  </Router>
  )
}

export default App;
